import { render, staticRenderFns } from "./sectionInfoDetail.vue?vue&type=template&id=38117467&scoped=true"
import script from "./sectionInfoDetail.vue?vue&type=script&lang=js"
export * from "./sectionInfoDetail.vue?vue&type=script&lang=js"
import style0 from "./sectionInfoDetail.vue?vue&type=style&index=0&id=38117467&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38117467",
  null
  
)

export default component.exports